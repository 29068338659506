import { createStore } from 'vuex'

export default createStore(
{
  state: {
    months_partner: [],
    advertisers: [],
    contacts: [],
    qp: [],
    scores: [],
    week_scores: [],
    leagues: [],
    access_token: null,
    parsed_token: null,
    months: null,
    compliance: [],
    loaded: false
  },
  mutations: {
    setAccessToken(state, token) {
      state.access_token = token;
    },
    setParsedToken(state, token) {
      state.parsed_token = token;
    },
    setMonthsPartner(state, months) {
      state.months = months;
    },
    setCompliance(state, compliance) {
      state.compliance = compliance;
    },
    setAdvertisers(state, advertisers) {
      state.advertisers = advertisers;
    },
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setQP(state, qp) {
      state.qp = qp;
    },
    setScores(state, scores) {
      state.scores = scores;
    },
    setWeekScores(state, scores) {
      state.week_scores = scores;
    },
    setLeagues(state, leagues) {
      state.leagues = leagues;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    }
  },
  actions: {
    setAccessToken(context, token) {
      context.commit('setAccessToken', token);
    },
    setParsedToken(context, token) {
      context.commit('setParsedToken', token);
    },
    setMonthsPartner(context, months) {
      context.commit('setMonthsPartner', months);
    },
    setCompliance(context, compliance) {
      context.commit('setCompliance', compliance);
    },
    setAdvertisers(context, advertisers) {
      context.commit('setAdvertisers', advertisers);
    },
    setContacts(context, contacts) {
      context.commit('setContacts', contacts);
    },
    setQP(context, qp) {
      context.commit('setQP', qp);
    },
    setScores(context, scores) {
      context.commit('setScores', scores);
    },
    setWeekScores(context, scores) {
      context.commit('setWeekScores', scores);
    },
    setLeagues(context, leagues) {
      context.commit('setLeagues', leagues);
    },
    setLoaded(context, loaded) {
      context.commit('setLoaded', loaded);
    }
  },
  getters: {
    getAccessToken(state) {
      return state.access_token;
    },
    getParsedToken(state) {
      return state.parsed_token;
    },
    getMonthsPartner(state) {
      return state.months;
    },
    getCompliance(state) {
      return state.compliance;
    },
    getAdvertisers(state) {
      return state.advertisers;
    },
    getContacts(state) {
      return state.contacts;
    },
    getQP(state) {
      return state.qp;
    },
    getScores(state) {
      return state.scores;
    },
    getWeekScores(state) {
      return state.week_scores;
    },
    getLeagues(state) {
      return state.leagues;
    },
    getLoaded(state) {
      return state.loaded;
    }
  },
  modules: {
  }
})
