<template>

  <div id="wrapper">
    <div v-if="!loaded" class="x-reset-container">
      <div class="im">
          <svg class="im__icon" idversion="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Leaf</title>
              <g class="c-leaf__icon-group">
                <path d="M76,0h129v135H0V0h35.4H76z M68.1,119.4V15.6h-12v103.7H68.1z
                M148.7,119.3V16.2l-29.5,51.3L89.9,16.2v103.1h11.9
                l-0.4-60.7l18,33.2l17.8-33.2l-0.3,60.7H148.7z"/>
              </g>
          </svg>
          <div class="im__fill"></div>
      </div>
    </div>
    <router-view v-if="loaded"/>
    </div>
</template>

<script>
  import { onMounted, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  export default {
    name: 'App',
    components: {
    },
    setup() {
      const router = useRouter()

      let store = useStore();
      // let token = store.getters.getParsedToken;
      // let token = {"partner_id": 20422}
    
      let loaded = computed(() => store.getters.getLoaded)


      loadData()

      function loadData() {

        const headers = new Headers();
        const access_token = store.getters.getAccessToken;
        if(access_token) {
            headers.append('Authorization', 'bearer ' + access_token);
        }

        let months = fetch(process.env.VUE_APP_MONTHS, { headers: headers }).then((response) => response.json());
        let compliance = fetch(process.env.VUE_APP_COMPLIANCE, { headers: headers }).then((response) => response.json());
        let advertisers = fetch(process.env.VUE_APP_ADVERTISERS, { headers: headers }).then((response) => response.json());
        let qp = fetch(process.env.VUE_APP_QP, { headers: headers }).then((response) => response.json());
        let scores = fetch(process.env.VUE_APP_SCORES, { headers: headers }).then((response) => response.json());
        let week_scores = fetch(process.env.VUE_APP_WEEK_SCORES, { headers: headers } ).then((response) => response.json());
        let leagues = fetch(process.env.VUE_APP_LEAGUES, { headers: headers }).then((response) => response.json());

      
        Promise.all(
            [months, compliance, advertisers, qp, scores, week_scores, leagues],
        ).then((fetched) => {  
            store.dispatch('setMonthsPartner', fetched[0])
            store.dispatch('setCompliance', fetched[1])
            store.dispatch('setAdvertisers', fetched[2])
            store.dispatch('setQP', fetched[3])
            store.dispatch('setScores', fetched[4])
            store.dispatch('setWeekScores', fetched[5])
            store.dispatch('setLeagues', fetched[6])
            store.dispatch('setLoaded', true)
            }
        )

      }

      onMounted(function() {
        const href = location.href
                .replace(/[&?]code=[^&$]*/, '')
                .replace(/[&?]scope=[^&$]*/, '')
                .replace(/[#?]state=[^&$]*/, '')
                .replace(/[&?]session_state=[^&$]*/, '');
        router.replace(href)
      })
      return {loaded}
    }
  }
</script>

<style>
.im {
    position: relative;
    overflow: hidden;
    /* width: 205px - 2px;
    height: 135px - 2px; */
    margin: 0 auto;
    background: #1e53bc;
    /* transform: scale(1); */
}

.im__icon {
    position: absolute;
    /* top: -1px;
    left: -1px; */
    z-index: 1;
    left: 0;
    width: 205px;
    height: 135px;
    fill: #1e53bc;
}

.im__fill {
    width: 205px;
    height: 135px;
    background: #ffffff;
    animation: fillUp 1.8s ease-in-out infinite;
}

@keyframes fillUp {
    0% {
        transform: translate(0, 135px);
    }
    100% {
        transform: translate(0, -135px);
    }
}

.x-reset-container {
    display: flex;
    align-items: center;
    /* padding: 50px; */
    height: 100vh;
}
</style>