import { createApp } from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import * as Keycloak from "keycloak-js";

let interval = false;

let initOptions = {
    url: process.env.VUE_APP_KEYCLOACK_URL || "https://35.157.183.54:8443/auth",
    realm: process.env.VUE_APP_KEYCLOACK_REALM || "demo",
    clientId: process.env.VUE_APP_KEYCLOACK_CLIENT_ID || "invoicer",
};

const keycloak = Keycloak(initOptions);
keycloak.init().then((auth) => {
    if (!auth) {
        keycloak.login()
    } else {
        console.info("Authenticated");
        store.dispatch('setAccessToken', keycloak.token)
        // store.dispatch('setParsedToken', {"partner_id": 22164 })
        store.dispatch('setParsedToken', keycloak.tokenParsed)

        const app = createApp(App, { keycloak })
        app.use(store).use(router).mount('#app');
        
        refreshInterval(keycloak)
    }
    }).catch(() => {
        console.error("Authenticated Failed");
    })


function refreshInterval(keycloak) {
    updateToken(keycloak)
    if (interval === false) {
        interval = setInterval(() => {
            updateToken(keycloak);
        }, 60000)
    }
}

function updateToken(keycloak) {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                store.dispatch('setAccessToken', keycloak.token)
                console.info('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
            }).catch(() => {
            console.error('Failed to refresh token');
        })
    }