<template>
  <div id="app">
    <!-- <img src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="reveal">
      <div class="slides">
        <section>
          <p class="bold">#IMPROVERSWRAPPED2021</p>
          <h1>Wat heeft 2021 bij Improvers jou gebracht?</h1>
          <p>De gegevens zijn gebaseerd op de netto facturaties van 2021. <br> <br>
          Deze experience werkt het beste op je desktop. Gebruik de pijltjes rechtsonderin om te navigeren.</p>
        </section>
        <section data-transition="slide">
          <p>Improvers bestaat dit jaar 10 jaar en jij bent al</p>
          <h1 class="bold">{{months_for_partner.months}} maanden</h1>
          <p>Partner van Improvers.</p>
          <p>We hopen uiteraard dat onze samenwerking nog vele jaren voortduurt.</p> 
        </section>
        <section data-transition="slide">
          <div class="two_blocks">
            <div class="align-right">
              <p>In 2021 heb je in totaal</p>
              <h2 class="bold">{{get_format(my_scores.my_sales)}}</h2>
              <p>Sales</p>
              <h2 class="bold">{{get_format(my_scores.my_network)}}</h2>
              <p>Netwerk</p>
              <h2 class="bold">{{get_format(my_scores.my_custom)}}</h2>
              <p>Overige</p>
              <p class="last">omzet gemaakt</p>
              <p class="discl">* Dit is exclusief extra's zoals de Big Battle bonus 2020, Imperium Coins en de tijdelijke compensatie D2D
              </p>
            </div>
            <div class="align-left">
              <div class="best">
                <p class="bold sapphire">BESTE VERTICALS</p>
                  <ol>
                    <li v-for="vertical in my_scores.my_best_verticals" :key="vertical.vertical">{{vertical.vertical}}</li>
                  </ol> 
              </div>
              <div class="best">
                <p class="bold sapphire">BESTE ADVERTEERDERS</p>
                  <ol>
                    <li v-for="advertiser in my_scores.my_best_advertisers" :key="advertiser.advertiser">{{advertiser.description}}</li>
                  </ol>
              </div>
            </div>
          </div>
        </section>
        <section data-transition="slide">
          <div>
            <p>Met die omzet ben je op de</p>
            <div class="high_letter"><h1 class="bold">{{my_scores.my_rank}}</h1><p>e</p>
            </div>
            <p class="ranking">plaats geëindigd in de Improvers omzet ranking</p>
              <ul class="sky_blue">
                <li :class="{ sapphire: partner.this_partner, bold: partner.this_partner }" v-for="partner in my_scores.my_ranking_sliced" :key="partner.index">{{partner.index}}. {{partner.first_name}} {{partner.last_name}}</li>
              </ul>
            </div>
        </section>
        <section data-transition="slide">
          <section data-transition="slide">
            <h2>Jouw rankings in de Improvers Leagues </h2>
          <div class="three_columns_container">
              
              <div class="limburg three_columns_item">
                  <h1 class="bold">Limburg</h1>
                  <p class="trip">Champions League 2021 H1</p>
                  <img class="verif" v-if="my_scores.my_leagues.clh1 == true" src="../assets/verified_black_24dp.svg"/>
                  <img class="verif" v-else src="../assets/non_verified_black_24dp.svg"/>
              </div>  

              <div class="budapest three_columns_item">
                  <h1 class="bold">Budapest</h1>
                  <p class="trip">Champions League 2021 H2</p>
                  <img class="verif" v-if="my_scores.my_leagues.clh2 == true" src="../assets/verified_black_24dp.svg"/>
                  <img class="verif" v-else src="../assets/non_verified_black_24dp.svg">
              </div>

              <div class="choose three_columns_item">
                  <h1 class="bold">Your choice</h1>
                  <p class="trip">Diamond League 2021</p>
                  <img class="verif" v-if="my_scores.my_leagues.diamond == true" src="../assets/verified_black_24dp.svg"/>
                  <img class="verif" v-else src="../assets/non_verified_black_24dp.svg">
              </div>

          </div>
           
        </section>
        <section data-transition="slide">
          <div class="player">
            <h2>Ga jij volgende keer ook mee? </h2>
          <iframe src="https://player.vimeo.com/video/641186672?h=a910cb0231&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="600" height="338" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Limburg - Champions Trip 2021"></iframe>
            </div>
        </section>
          </section>
        <section data-transition="slide">
          <div class="center">
            <div class="sky_blue top" style="width: 600px">
                <p class="bold sapphire topcamp">JOUW TOP CAMPAGNES VAN 2021</p>
                <div class="align-left">
                  <ol>
                    <li v-for="project in my_scores.my_best_projects" :key="project.project_code">{{project.description}}</li>
                  </ol> 
                </div>
              </div>
          </div>
        </section>
        <section>
          <p>Uiteraard blijft kwaliteit hoog in het vaandel staan bij Improvers</p>
          <p>Gemiddeld was jouw compliance in 2021.</p>
          <template v-if="my_scores.compliance && my_scores.compliance > 0">
            <h1 class="bold">{{my_scores.compliance}}%</h1>
            <p>Daarmee behoor jij tot de top {{my_scores.compliance_top}}% van Improvers.</p>
          </template>
        </section>
        <section class="end_card_share">
          <div class="center">
          <div class="sky_blue" style="width:500px">
            <div>
              <img src="../assets/rgb_10improvers_sappghire.png">
              <p class="hashtag">#IMPROVERSWRAPPED2021</p>
            </div>
            <div>
              <p class="sapphire">Sales gemaakt</p>
              <h4 v-if="my_scores.my_week_scores">{{get_sale_count(my_scores.my_week_scores)}}</h4>
              <p class="sapphire">Favoriete vertical</p>
              <h4>{{get_top_vertical(my_scores.my_best_verticals)}}</h4>
              <p class="sapphire">Compliance</p>
              <h4 v-if="my_scores.compliance && my_scores.compliance > 0">{{my_scores.compliance}}%</h4>
              <h4 v-else>Niet beschikbaar</h4>
            </div> 
            <div>
              <p class="sapphire">Top Adverteerders</p>
              <ol class="top3">
                <li v-for="advertiser in my_scores.my_best_advertisers" :key="advertiser.advertiser">{{advertiser.description}}</li>
              </ol> 
              <p class="sapphire">Top weken</p>
              <ol>
                <li v-for="week in my_scores.my_week_scores" :key="week.weekno">Week {{week.weekno}} {{get_format(week.amount)}}</li>
              </ol>
            </div>
            <!-- <p>Deel jouw jaaroverzicht </p> -->
          </div>
          </div>
        </section>
        </div>
    </div>
  </div>
</template>

<script>
import Reveal from 'reveal.js';
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'Home',
      components: {
    },
  setup() {
        let store = useStore();
        let token = store.getters.getParsedToken;
        
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        })

        const formatter_number = new Intl.NumberFormat('nl-NL')


        function get_sale_count(week_scores) {
          if (week_scores && week_scores.length > 0) {
            return get_number_format(week_scores[0].count_as_sale_all)
          }
          return 0
        }

        function get_top_vertical(verticals) {
          if (verticals && verticals.length > 0) {
            return verticals[0].vertical
          }
          console.log('vertical',verticals)
          return 'Niet beschikbaar'
        }

        function get_format(value) {
          return formatter.format(value)
        }

        function get_number_format(value) {
          return formatter_number.format(value)
        }

        let months_for_partner = computed(get_months_for_partner)
        let my_scores = computed(get_scores)


        function get_scores() {
          let partners = store.getters.getMonthsPartner
          let scores = store.getters.getScores
          let my_week_scores = store.getters.getWeekScores

          let my_scores = scores.filter((el) => el.partner_id == token.partner_id)

          let my_sales = 0.00
          let my_total = 0.00
          let my_network = 0.00
          let my_custom = 0.00

          if (my_scores && my_scores.length > 0) {
            my_sales = parseFloat(my_scores[0].sale_amount)
            my_total = parseFloat(my_scores[0].total_amount)
            my_network = parseFloat(my_scores[0].networker_amount)
            my_custom = parseFloat(my_scores[0].custom_amount)
          }

          let stored_advertisers = store.getters.getAdvertisers;
          
          let my_best_advertisers = my_scores.reduce((acc,cur) => {
            let found = acc.filter((el) => el.advertiser == cur.advertiser)
            if (!found || found.length == 0) {
                let description = stored_advertisers.find((el) => el.code == cur.advertiser)
                
                if(description) {
                  description = description.name
                } else {
                  description = get_closed_advertiser(cur.advertiser)
                }
                acc.push({...cur, 'description': description })
            }
            return acc
          },[]).sort((a,b) => b.advertiser_amount - a.advertiser_amount).slice(0, 4);


          let leagues_data = store.getters.getLeagues

          let my_leagues = leagues_data.reduce((acc,cur) => {
            
            let trip = cur.trip
            let has_trip = cur.partners.some((el) => el.partner_id == token.partner_id)
            if (has_trip && trip == 'Champions Trip 2021 H1') {
              acc.clh1 = true
            }
            if (has_trip && trip == 'Champions Trip 2021 H2') {
              acc.clh2 = true
            }
            if (has_trip && trip == 'Diamond Trip 2021') {
              acc.diamond = true
            }
            return acc
          },{'clh1': false, 'clh2': false, 'diamond': false})

          let my_best_verticals = my_scores.reduce((acc,cur) => {
                let vertical = get_vertical(cur.advertiser)
                let found = acc.find((el) => el.vertical == vertical)

                if (found) {
                    found.vertical_amount += parseFloat(cur.project_amount)
                } else {
                    let vertical_amount = parseFloat(cur.project_amount)
                    acc.push( { 'vertical': vertical, 'vertical_amount': vertical_amount } )
                }
                return acc
          },[]).sort((a,b) => b.vertical_amount - a.vertical_amount).slice(0, 4);

          let stored_qp = store.getters.getQP;

          let my_best_projects = my_scores.reduce((acc,cur) => {
            let found = acc.filter((el) => el.project_code == cur.project_code)
            if (!found || found.length == 0) {
                let project = stored_qp.find((el) => el.code == cur.project_code)
                if(project) {
                  project = project.description
                } else {
                  console.log('not-found',cur.project_code)
                  project = get_campaign(cur.project_code)
                }
                acc.push({...cur, 'description': project })
            }
            return acc
          },[]).sort((a,b) => b.project_amount - a.project_amount).slice(0, 4);

          let compliance = store.getters.getCompliance;
          let found_compliance = compliance.find((el3) => el3.partner_id == token.partner_id)

          let partner_compliance = 0
          if(found_compliance && found_compliance.aantal_behaald > 0 ) {
              partner_compliance = parseInt(found_compliance.compliance)
          }

          let compliance_stats = partners.reduce((acc,cur) => {
            
            if (cur.partner_id != token.partner_id) {
              let compliance_item = compliance.find((el3) => el3.partner_id == cur.partner_id)
              
              if (compliance_item && compliance_item.aantal_behaald > 0 ) {
                let this_compliance = parseInt(compliance_item.compliance)
                if (this_compliance > partner_compliance && partner_compliance > 0) {
                  acc.more += 1
                  acc.total += 1
                  acc.top += 1
                } else if (this_compliance < partner_compliance && partner_compliance > 0) {
                  acc.less += 1
                  acc.total += 1
                } else if (this_compliance == partner_compliance && partner_compliance > 0) {
                  acc.same += 1
                  acc.total += 1
                  acc.top += 1
                }
              }
            }

            return acc
          },{'more':0,'less':0,'same': 0, 'total': 0, 'top': 0})

          let compliance_top = compliance_stats.total > 0 ? Math.round((compliance_stats.top / compliance_stats.total) * 100) : 0
          console.log('compliance_top',compliance_top)


          let my_ranking = partners.reduce((acc,cur) => {
                
                let found_scores = scores.find((el2) => el2.partner_id == cur.partner_id)
                
                let total_amount = 0.0
                let rank = 1000
     
                if(found_scores) {
                  total_amount = parseFloat(found_scores.total_amount)
                  rank = parseInt(found_scores.rank)
                  let result = {'partner_id': cur.partner_id, 'first_name' : cur.first_name, 'last_name': cur.last_name, 'total_amount': total_amount, 'rank': rank, 'this_partner': cur.partner_id == token.partner_id}
                  acc.push(result)
                }
                return acc
  
            },[]).sort((a,b) => a.rank - b.rank).map((el, index) => { return {...el,'index': index + 1}});

            let partner_idx = my_ranking.findIndex((el) => {
                return el.partner_id == token.partner_id
            })
            
            let min_a = partner_idx - 5
            let max_b = partner_idx + 6

            if (min_a < 0) {
              max_b = max_b + Math.abs(min_a)
            }

            let max = Math.min(max_b, my_ranking.length - 1)

            if (max_b >= my_ranking.length - 1 ) {
              let diff = max_b - (my_ranking.length - 1)
              console.log('diff', diff,my_ranking.length - 1 )
              min_a = min_a - diff
              max_b = max_b - diff + 1
              max = max_b
            }

            // diff 6 449
            // Home.vue?bb51:378 min 438 438
            // Home.vue?bb51:379 max 455 449

            let min = Math.max(min_a, 0)
            // let max = Math.min(max_b, my_ranking.length - 1)
            console.log('min',min_a,min)
            console.log('max',max_b,max)

            let my_ranking_sliced = my_ranking.slice(min, max)

          return {my_sales, my_leagues, my_total, my_network, my_custom, my_best_projects, my_best_verticals, my_best_advertisers, my_week_scores, my_ranking_sliced,'compliance': partner_compliance, 'compliance_top': compliance_top, 'my_rank': partner_idx + 1}

        }


        function get_closed_advertiser(adv) {
            
            if (adv == 'ESPP') {
                return 'Essent ServicePartners'
            } else if (adv == 'EVBX') {
                return 'Vandebron Opt-in Leads'
            } else if (adv == 'MTMG') {
                return 'Mediahuis'
            } else if (adv == 'EDEA') {
                return 'Door2Door'
            }
            return adv
        }

        function get_campaign(campaign) {
          if (campaign == 'EDEA02') {
            return 'Door2Door'
          } else if (campaign == 'EDEX01') {
            return 'Energiedirect Opt-In'
          } else if (campaign == 'EDED01') {
            return 'Energiedirect Door2Door'
          }
          return campaign
        }


        function get_vertical(vert) {
            let fchar = vert[0]
            if (fchar == "M") {
                return "Media"
            }
            if (fchar == "C") {
                return "Charity"
            }
            if (fchar == "L") {
                return "Lottery"
            }

            return "Energy";

        }

        function get_months_for_partner() {
            let months = store.getters.getMonthsPartner
            let parsed_token = token;
            console.log(months)
            if (months && parsed_token) {
                let result = months.find((el) => { 
                    return el.partner_id == parsed_token.partner_id
                })
                console.log(result)
                return result
            }
        }


        onMounted(function() {
            Reveal.initialize()
        })

        return { months_for_partner, get_format, get_sale_count, get_top_vertical, my_scores }
    }
}
</script>

<style>
@import url('../../node_modules/reveal.js/dist/reveal.css');
@import url('../../node_modules/reveal.js/dist/theme/black.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
}
#app {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  height: 100vh;
  background-image: url(../assets/background.png);
}

#app .two_blocks .align-right h2 {
    margin-top: 15px;
    margin-bottom: 0px;
}

.last {
  margin-top: 15px;
}

.reveal .topcamp {
  margin-bottom: 15px;
}

.limburg {
background-image: url(../assets/champ1.png);
background-size: cover;
background-repeat: no-repeat;
}

.budapest {
background-image: url(../assets/champ2.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.choose {
background-image: url(../assets/diamond.png);
    background-size: cover;
    background-repeat: no-repeat;
}


.reveal h1, .reveal p, .reveal li, .reveal ol, .reveal ul {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.verif {
  width: 60%;
}

.verif path {
  fill: #68d8e1;
}

#app .reveal .discl {
    font-size: 10px;
    max-width: 50%;
    margin-left: 50%;
}

.reveal ul {
    list-style-type: none;
    padding: 20px;
}

.reveal ol, .reveal ul {
  margin: 0 -15px 0 0;
}

.reveal .sapphire {
  color: #1e53bc; 
}

.controls-arrow {
    zoom: 2;
    color: white;
}

#app h1 {
  font-size: 60px;
}

.top3 li:nth-child(n+4) {
   display: none
}

#app h2 {
  font-size: 40px;
}

#app h4 {
  text-transform: none;
}
#app p, #app li {
  font-size: 20px;
}

.reveal .bold {
  font-weight: 1000;
}

.reveal .controls .navigate-left {
  margin-right: 35px
}

.reveal .controls .navigate-right {
  margin-left: 35px
}

.reveal .controls .navigate-down {
    right: 4.9em;
    bottom: -3.8em;
}

.reveal .controls .navigate-up {
    right: 4.9em;
    bottom: 8.3em;
}

.reveal .controls {
    bottom: 30px;
    right: 30px;
}

#app .two_blocks {
    display:flex;   
}

#app .two_blocks > div {
    width:50%;
    margin: auto;
    padding: 15px;
}

#app .two_blocks .best {
  background-image: url(../assets/background_skyblue_1.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 25px;
  margin-bottom: 10px;
  height: 140px;
}

#app .two_blocks .best:nth-of-type(2) {
  background-image: url(../assets/background_skyblue_2.png);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 25px;
  margin-bottom: 10px;
  height: 140px;
  }


.align-right {
  text-align:right; 
}
.align-left {
  text-align:left; 
}

.high_letter{
  display:flex;
  justify-content: center;
}

.sapphire {
  color:#1e53bc;
}
.sky_blue {    
  background-color: #68d8e1;   
}

.top {
    padding: 25px;
}

.center {
  display:flex;
  justify-content: center;
}

/* .three_columns_container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
} */

.three_columns_container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 12px;
}

.three_columns {
  width: 30%;
  padding:15px; 
}

.three_columns > p {
  padding-bottom: 10px;
}

.three_columns_item {
  align-items:center;
}

#app .three_columns_item h1{
  padding: 40px 0px 10px 0px;
  align-items:center;
  font-size: 40px;
}

#app .three_columns_item li {
  font-size: 15px;
}

.end_card_share div{
  display:flex;
  flex-wrap: wrap;
  align-items:flex-start;
  text-align:left;
}

.end_card_share .center .sky_blue div:first-child {
  align-items: flex-end;
  
}

.end_card_share .center .sky_blue{
  padding: 25px 25px;

}

.end_card_share div div:nth-child(2), .end_card_share div div:nth-child(3){
  width:50%;
}

.end_card_share div div div:nth-child(2) p, .end_card_share div div div:nth-child(2) h4, .end_card_share div div div:nth-child(3) p, .end_card_share div div div:nth-child(3) h4{
  width:100%;
}

.end_card_share div div div:first-child{
  justify-content:space-between;
  width:100%;
  margin-bottom:50px;
}

.end_card_share img {
  width: 100px;
  margin: 0 10px 0 0;  
}

#app .end_card_share .hashtag{
  margin: 0px 0px -6px 0;
  font-size: 15px;
  font-weight:700;
}

.reveal .end_card_share ol {
  margin: 0 0 20px -15px;
}

.reveal .end_card_share ul {
  margin: 0 0 20px -15px;
}

.reveal .ranking_sticky_bottom ol {
  margin: -15px -15px 0 0 ;
}

.reveal .ranking_sticky_bottom ul {
  margin: -15px -15px 0 0 ;
}

.reveal .ranking_sticky_bottom ol li, .reveal .ranking_sticky_bottom ul li {
  font-size: 15px;
}

.reveal .ranking {
  margin-bottom: 15px;
}

</style>